@import 'src/assets/variables';

.contacts {
  background: $globalAccent;
  height: 100vh;
  padding: 100px 150px;
  display: flex;
  align-items: center;
}

.contacts-heading {

  opacity: 0;
  transition: all 1s;
}

.contacts-wrapper {
  display: flex;
}

.contacts-cards {
  margin-left: 100px;
  display: flex;
  justify-content: end;
  transform: translate3d(100vw, 0, 0);
  transition: all 1s;

}

@media (width < 980px) {
  .contacts {
    padding: 20px 10px;
    height: 100%;

  }
  .contacts-wrapper {
    flex-direction: column;
    width: 100%;
  }
  .contacts-cards {
    flex-direction: column;
    margin: 0;
  }
}