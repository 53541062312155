@import 'src/assets/variables';


.contact-card {
  margin: 10px;
  background: $white;
  border-radius: 15px;
  padding: 50px 42px;
  font-size: 24px;
}

.card-header {
  display: flex;
  white-space: pre-line

}

.avatar {
  width: 122px;
  height: 168px;
  border-radius: 15px;
}

.manager-name {
  font-size: 32px;
  font-weight: 900;
  color: $globalAccent;
  text-transform: uppercase;

}

.card-header-info {
  font-size: 24px;
}

.card-header-info p {
  margin-top: 10px;
  line-height: 30px;
}

.card-contacts div{
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.card-contacts p {
  margin-left: 30px;
}

.card-contacts a {
  height: 40px;
  border-radius: 15px;
  background: $globalAccent;
  border: none;
  margin-right: 16px;
  color: $white;
  text-transform: uppercase;
  font-size: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (width < 980px) {
.manager-name p{
  font-size: 24px;
}
  .card-header-info p{
    font-size: 18px;
    line-height: 22px;
  }

  .card-contacts div p {
    font-size: 20px;
  }
}
