@import 'src/assets/variables';

.other {
  margin:10vh 0;
  padding: 0 $width-margin;
  height: 100vh;
  display: flex;
  align-items: center;
  background: $white;
}

.other-header {
  display: flex;
  justify-content: space-between;
}

.other-wrapper {
  position: relative;
}

.other-heading {
  transform: translate3d(-100vw, 0, 0);
  transition: all 1s;
}
.other-info {
  display: flex;
  height: 100%;
  align-items: center;
}

.other-description {
  width: 45vw;
  flex-wrap: wrap;
  font-size: 32px;
  padding: 5vw;
  //margin-left: 200px;
  font-weight: 500;
  transform: translate3d(100vw, 0, 0);
  transition: all 1s;
}

.other-systems {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.other-system {
  width: 45vw;
  display: flex;
  align-items: center;

  //transform: translate3d(-350px, 0, 0);
  flex-direction: row-reverse;
}
//
//.other-system:nth-child(2) {
//  transform: translate3d(350px, 0, 0);
//  flex-direction: row;
//}

.other-system-img {
  border: 5px solid $red;
  border-radius: 50%;
  min-width: 140px;
  height: 140px;
  max-width: 140px;
  display: flex;
  position: relative;
  margin: 20px 20px;
  opacity: 0;
  transition: all 1s
}

.other-system-img img {
  height: 100px;
  width: 100px;
}

.other-system-img::after {
  content: '';
  border-left: 5px solid $red;
  position: absolute;
  height: 136px;
  transform: rotate(45deg);
  left: 50%;
  top: 0;
}

.other-system img {
  height: 110px;
  width: 110px;
  margin: auto auto;
}

.other-system-name {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: pre-line;
}

.other-system-description {
  text-align: end;

  //font-size: 24px;
  //width: 0;
  // исправить при аниммации
  width: 700px;
  //max-height: 15%;
  overflow: hidden;
  transition: all 1s;
  //max-height: 300px;
  white-space: pre;
}

.other-system-description p {
  //max-width: 60%;
  //margin-left: 40%;
}
//
//.two {
//  margin-left: -32%;
//}

@media (width < 980px) {
  .other {
    padding: 0 10px;
    height: 100%;
  }

  .other-info {
    flex-direction: column-reverse;
  }
  .other-system {
    flex-direction: column;
  }

  .other-system {
    transform: translate3d(-0vw, 0, 0);
  }

  .other-system:nth-child(2) {
    transform: translate3d(0vw, 0, 0);
  }

  .two {
    margin-left: -10%;
  }
  .other-system-name {
    font-size: 28px;
  }

  .other-description {
    margin: 0;
    font-size: 18px;
    width: 100%;
  }

  .other-system-description {
    text-align: center;
    width: 60vw;
  }
  .other-system-description p {
    font-size: 18px;
  }
  .other-header {
    justify-content: start;
    flex-direction: column;
  }
}