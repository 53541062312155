@import 'src/assets/variables';

.footer {
  padding: 10px 150px;
  height: 15vh;
  background: $black;
  display: flex;
  justify-content: center;
}

.footer-contacts {
  display: flex;
  align-items: center;
  margin-bottom: 1%;
  font-size: 18px;
}

.footer-contacts a {
  color: white;
}

.footer-contacts a:hover {
  color: #0AACBE;
  transition: all .3s;
}

@media (width < 980px) {
.footer {
  flex-direction: column;
  height: 100%;
  align-items: center;

}
}
