@import 'src/assets/variables';

.realization {
  padding: 0 $width-margin;
  height: 100vh;
  background: $white;
}

.realization-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 200px;
}

.realization-wrapper {
  position: relative;
}

.realization-heading {
  //transition: all 1s;
  //opacity: 0;
}

.realization-description {
  flex-wrap: wrap;
  font-weight: 500;
}

//
//.steps-wrapper {
//  transition: transform 2s;
//}

.stages-arrow {
  position: absolute;
  left: -65px;
  transform: translate(10vw, -20vw) rotate(180deg);
  //margin-top: -250px;
  //width: 0;
  //display: flex;
  //align-items: center;
  //margin-left: 20px;
}


.stages-line {
  height: 55vh;
  min-width: 25px;
  max-width: 25px;
  background-color: $globalAccent;
}

.stages-line-arrow {
  //opacity: 0;
  border: solid $globalAccent;
  width: 120px;
  height: 120px;
  border-width: 0 25px 25px 0;
  display: inline-block;
  transform: translate(-47.8px, -120px) rotate(45deg);
}

.stages {
  position: absolute;
  height: 26vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  //align-items: center;
}

.stage {
  display: flex;
  align-items: start;
}

.stages img {
  height: 70vh;
  width: 60vw;
  object-fit: fill;
  position: absolute;
}

.stage-hidden {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 1s;
  font-weight: 500;
}

.stage-show {
  opacity: 1;
  max-height: 100%;
  overflow: hidden;
  font-size: 24px;
  font-weight: 500;
  transition: all 1s;
}

.stage div {
  margin-left: 20px;
  font-size: 32px;
  font-weight: 500;
}

.stage-number {
  transition: opacity 0.5s;
  border: 5px solid $globalAccent;
  background-color: $white;

  font-size: 64px;
  font-weight: 900;
  color: $globalAccent;

  height: 80px;
  width: 80px;
  min-width: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  //transform: translate3d(-20px,0,0);
  z-index: 100;
}

.realization-content {
  display: flex;
  align-items: center;
}

.fill {
  color: $white;
  background-color: $globalAccent;
}

@media (width < 980px) {
  .realization {
    padding: 0 10px;
    height: 80vh;
  }

  .realization-description {
    font-size: 18px;
    width: 100%;
    margin-left: 10px;
  }

  .realization-header {
    margin: 0;
  }
  .realization-content {
    align-items: start;
    position: relative;
  }
  .stage {
    align-items: center;
  }
  .stages {
    margin-top: 5vh;
    height: 45vh;
    top: 15vh;
    left: 5vw;
  }
  .stage div p {
    font-size: 18px;
  }
  .stages-arrrow {
    left: -10vw;
  }
}
