@import 'src/assets/variables';

.solve {
  background: $white;
  transition: all 1s;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solve-heading {
  opacity: 0;
  transition: all 1s;
}

.solve-wrapper {
  padding: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.problems {
  display: flex;
}

.problems .problems-img {
  opacity: 0;
  transition: transform 1s;
  transform: translate3d(0, -100vh, 0);
  margin: 50px 10px 0 10px;
  width: 30vw;
  display: flex;
  flex-direction: column;
}

.problems .problems-img:nth-child(2) {
  transition: all 1s;
  opacity: 0;
  transform: translate3d(0, 100vh, 0);
  margin: 50px 10px 0 10px;
}


@media (width < 980px) {

  .solve {
    height: 100%;
    padding: 20px 10px;
    margin: 5vh 0 ;

  }

  .solve-wrapper {
  }
  .problems {
    flex-direction: column;
  }

  .problems .problems-img {
    width: 95vw;
    p {
      font-size: 20px;
    }
  }

}