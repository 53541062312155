#circle1 {
  z-index: 100;

}

#circle2 {
  z-index: 100;

}

#circle3 {
  z-index: 100;

}
#circle4 {
  z-index: 100;

}
#circle5{
  z-index: 100;

}

.dot-map {
}

.dot-hover {
  rx: 15px;
  ry: 15px;
  transition: all .5s;
}

.card-map5  {
  width: 0;

  z-index: -5;
  opacity: 0;
  transition: all 0.2s;
}

.card-map-img5 {
  width: 0;
  transition: all 0.2s;
  z-index: -5;
  opacity: 0;
}

.card-map-text5 {
  width: 0;

  z-index: -5;
  transition: all 0.2s;
  opacity: 0;
}
.card-map4 {
  width: 0;

  z-index: -5;
  opacity: 0;
  transition: all 0.2s;
}
.card-map-img4 {
  width: 0;

  transition: all 0.2s;
  z-index: -5;
  opacity: 0;
}
.card-map-text4 {
  width: 0;

  z-index: -5;
  transition: all 0.2s;
  opacity: 0;
}
.card-map3 {
  width: 0;

  z-index: -5;
  opacity: 0;
  transition: all 0.2s;
}
.card-map-img3 {
  width: 0;

  transition: all 0.2s;
  z-index: -5;
  opacity: 0;
}
.card-map-text3 {
  width: 0;

  z-index: -5;
  transition: all 0.2s;
  opacity: 0;
}
.card-map2 {
  width: 0;

  z-index: -5;
  opacity: 0;
  transition: all 0.2s;
}
.card-map-img2 {
  width: 0;

  transition: all 0.2s;
  z-index: -5;
  opacity: 0;
}
.card-map-text2 {
  width: 0;

  z-index: -5;
  transition: all 0.2s;
  opacity: 0;
}
.card-map1  {
  width: 0;

  z-index: -5;
  opacity: 0;
  transition: all 0.2s;
}
.card-map-img1{
  width: 0;
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.5);
  transition: all 0.2s;
  z-index: -5;
  opacity: 0;
}
.card-map-text1 {
  width: 0;

  z-index: -5;
  transition: all 0.2s;
  opacity: 0;
}

.show {
  //display: block;
  opacity: 1;
  z-index: 10;
  width: 350px;

}
@keyframes show {
  0% {
    ry: 0;
    rx: 0;
    opacity: 0;
    stroke-width: 2;

  }
  50% {
    opacity: 1;
  }
  100% {
    ry: 60;
    rx: 60;
    opacity: 0;
    stroke-width: 1;

  }
}



@keyframes pulseMap1 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes pulseMap2 {
  0% {
    ry: 20;
    rx: 20;
    opacity: 0;
    stroke-width: 2;

  }
  50% {
    opacity: 1;
  }
  100% {
    ry: 60;
    rx: 60;
    opacity: 0;
    stroke-width: 1;

  }
}

@keyframes pulseMap3 {
  0% {
    ry: 40;
    rx: 40;
    opacity: 0;
    stroke-width: 2;
  }
  50% {
    opacity: 1;
  }
  100% {
    ry: 60;
    rx: 60;
    opacity: 0;
    stroke-width: 1;

  }
}