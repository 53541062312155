@import 'src/assets/variables';

.map {
  height: 100%;
  background: $white;
}

.map-heading {
  margin-right: 150px;
  transform: translate3d(100vw, 0, 0);
  transition: all 1s;
}

.map-img {
  display: flex;
  margin-left: -60%;
  justify-content: center;
  margin-top: -120px;
}

.dots-wrapper {
  position: relative;
  opacity: 0;
  transition: all 1s;
  width: 70vw;
}

.card {
  width: 190px;
  height: 230px;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  opacity: 0;
  transition: 0.5s;
}

.cardShow {
  opacity: 1;

}

.card div {
  margin-top: 20px
}

.card img {
  border-radius: 15px;
  max-height: 150px;
}

.dot {
  position: absolute;
  background-color: red;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}


.dot-card {
  height: 15px;
  width: 15px;
}

.dot-card::after,
.dot-card::before {
  content: '';
  position: absolute;
  border: 2px solid red;
  left: -20px;
  opacity: 0;
  right: -20px;
  top: -20px;
  bottom: -20px;
  border-radius: 50%;
  animation: pulse 2.5s linear infinite;
}

.dot-card::after {
  animation-delay: 1.25s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@media (width < 980px) {
  .map {
    height: 100%;
    padding: 20px 0;
  }
  .map-img {
    margin-left: -190%;
    margin-top: -5vh;
    width: 400%;
  }
  .end {
    text-align: start;
  }
}