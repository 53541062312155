@import '../../assets/variables';

.charts {
  background-color: $white;
  padding: 0 150px;
  display: flex;
  align-items: center;
}

.charts-header {
  display: flex;
  justify-content: space-between;
}

.charts-heading button {
  padding: 0 20px;
  margin: 10px 10px;
}

.button-active {
  background: $globalAccent;
  color: white;
  height: 50px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
}

.chart-diagram {
  cursor: pointer;
}
.charts-description {
  width: 50%;
}


@media (width < 980px) {
  .charts {
    padding: 10px 10px;
    height: auto;
  }
  .charts-heading button {
    margin: 1vh 10px;
  }
  .charts-header {
    flex-direction: column;
  }
  .charts-description {
    font-size: 18px;
    width: 100%;
  }
  //.charts-block {
  //  height: 100%;
  //}
}
