@import 'src/assets/variables';

.how-work-static {
  height: 100vh;
  background: $white;
  display: flex;
  align-items: center;
}

.how-work-wrapper-static {
  position: relative;
}

.how-work-header-static {
  padding: 0 $width-margin;

  position: absolute;
  width: 100vw;
  text-transform: uppercase;
  color: $globalAccent;
  font-weight: 900;
  top: -200px;

}

.steps-wrapper-static {
  transition: transform 2s;

}

.arrow-static {
  width: 80%;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.line-static {
  transition: width 0.5s;
  margin-left: 200px;
  width: 100vw;
  height: 45px;
  background-color: $globalAccent;
}

.line-arrow-static {
  opacity: 1;
  border: solid $globalAccent;
  border-width: 0 45px 45px 0;
  display: inline-block;
  margin: -45px -60px -45px 0;
  padding: 60px;
  transform: translate(-137px, 0) rotate(-45deg);
}

.steps-static {
  padding: 0 $width-margin;
  position: absolute;
  width: 78%;
  display: flex;
  top: -65px;
  justify-content: space-between;
}

.step-static {
  position: relative;
  transition: opacity 0.5s;
  border: 5px solid $globalAccent;
  background-color: $white;

  height: 180px;
  min-width: 180px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.step-static p {
  position: absolute;
  bottom: -100px;
  height: 50px;
  text-align: center;
  width: 280%;
}

.full-static {
  position: relative;
  transition: opacity 0.5s;
  border: 5px solid $globalAccent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $globalAccent;
  height: 180px;
  min-width: 180px;
}

.full-static p {
  position: absolute;
  bottom: -100px;
  height: 50px;
  text-align: center;
  width: 250%;
}


@keyframes dot {
  from {
    height: 0;
    width: 0;
  }
  to {
    height: 180px;
    width: 180px;;
  }
}

@media (width < 1500px) {
  .steps-static p {
    font-size: 18px;
    width: 240%;
  }

  .full-static p {
    width: 210%;
  }
}

@media (width < 1300px) {
  .steps-static p {
    width: 180%;
  }

  .full-static p {
    width: 150%;
  }
}

@media (width < 1100px) {
  .steps-static p {
    width: 160%;
  }

  .full-static p {
    width: 130%;
  }
}

@media (width < 980px) {

  .how-work-static {
    height: 100%;
    padding: 20px 0;
  }
  .how-work-header-static {
    font-size: 32px !important;
    opacity: 1;
    position: static;
    padding: 10px;
  }
.full-static {
  opacity: 1;
}

  .step-static {
    opacity: 1;
  }

  .steps-static {
    flex-direction: column;
    position: static;
    padding: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .steps-static p {
font-size: 18px;
    width: 200%;
  }

  .steps-wrapper-static {
    width: 100%;
  }

  .steps-static .full-static,
  .steps-static .step-static {
    margin: 90px 0;
  }

  .steps-static .last-step-static {
    margin: 190px 0;
  }
}
