
body {
  margin: 0;
  font-family: 'MuseoSansCyrl', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    /* scroll */
  scroll-behavior: smooth;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  width: 0;
}

@font-face {
  font-family: 'MuseoSansCyrl';
  src: local('MuseoSansCyrl'),
  url('./fonts/MuseoSansCyrl-500.otf') format('truetype');
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
