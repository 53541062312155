@import '../../../assets/variables';


.chart-one-table-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}


.chart-one-table-col div {
  padding: 6px 0;
}

.chart-two-legend {
  display: flex;
  align-items: center;
 }

.legend {
  width: 20px;
  //background: red;
  border-bottom: solid 1px black;
  //transform: translate(20px, -15px) rotate(127deg);
}

.charts-legend-line {
  //content: '_ _ _';
  color: rgba(0,0,0,0);
  max-width: 20px;
  border-bottom: solid 1px black;
  transform: rotate(89deg);
}

.chart-one-table-row {
  display: flex;
  height: 100%;
  margin-top: 30px;

}
.chart-one-table-row div {
  padding: 6px;
  width: 100%;
}

.chart-one-table-col-names {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6px 0;
  width: 100%;
}

.chart-one-table-col-names div {
  background: white ;
  width: 100%;
  text-align: center;
}

//.chart-one-table-col-names div:first-child {
//  background: $globalAccent;
//  width: 100%;
//  text-align: center;
//}
//
//.chart-one-table-col-names div:nth-child(2) {
//  background: red;
//  width: 100%;
//  text-align: center;
//}
//
//.chart-one-table-col-names div:nth-child(4) {
//  background: green;
//  width: 100%;
//  text-align: center;
//}

.chart-one-table {
  display: flex;
  //justify-content: space-between;
  align-items: start;
  padding: 20px;
  //background: #DDE7F0;
  border-radius: 25px;
  width: 100%;
}

.chart-one-table .diagram-one-legend {
  margin: 0;
}


@media (width < 980px) {
.chart-one-table {
  flex-direction: column;
}
}