@import 'src/assets/variables';

.partners {
  margin: 100px 150px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 50px 0;
}

.partners-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partners-line img {
  height: 60px;
  object-fit: fill;
  border-radius: 0;
}

@media (width < 980px) {
  .partners {
    margin: 0 10px;
  }

  .partners-line {
    flex-direction: column;
    align-items: center;
  }


  .partners-line img{
margin: 10px 0;
  }
}
