@import 'src/assets/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.link-active {
  color: #0AACBE;
  background: none;
}

.header div {
  margin: 0 5px;
}

.menu-name a {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  margin: 0 15px;
}


.menu-name a:hover {
  color: $globalAccent;
  transition: 0.5s;
  cursor: pointer;
}
.menu .menu-name{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu .menu-name a {
  margin-top: 30px;

}
.menu {
  height: 100vh;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(0,0,0, 0.8) ;
  position: absolute;
  display: flex;
}

.burger-button {
  background: none;
  border: none;
}
@media (width < 980px) {
  .about {
    font-size: 16px;
    padding: 0;
  }
  .about-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
  .info {
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
  .description {
    font-size: 18px;
    margin-top: 30vw;
    margin-left: 10px;
    margin-right: 10px;
  }
  .solut-name {
    line-height: 30vw;
    font-weight: 900;
    font-size: 30vw;
    text-shadow: 10px 5px #fff;
  }
  .about-img img {
    margin-top: 5vh;
    width:95vw;
    height: 30vh;
  }
  .header div {
    margin: 0;
  }
}