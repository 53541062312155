@import 'src/assets/variables';


.heading {
  text-transform: uppercase;
  color: $globalAccent;
  font-size: 48px;
  font-weight: 900;
  white-space: pre-line;
  transition: all 1s;
}

.end {
  text-align: end;
}

.white {
  color: $white;
}

.red {
  color: $red;
}

@media (width < 980px) {
  .heading h2 {
    font-size: 32px;
    margin: 10px;
  }
}