@import 'src/assets/variables';

.wedo-wrapper {
  height: 100vh;
  background: $white;
  padding: 0 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wedo {
  display: flex;
  align-items: start;
  width: 100%;
}

.wedo-heading {
  opacity: 0;
  transition: all 1s;
}

.wedo-img {
  transform: translate3d(-100vw, 0, 0);
  transition: transform 1s;
  display: flex;

}

.wedo-img img {
  width: 45vw;
  height: 80vh;
}

.point {
  background-color: $globalAccent;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 30px;
}


.wedo-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}

.wedo-list {
  margin: auto 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.point-wrapper {
  margin-top: 44px;

  opacity: 0;
  transform: translate3d(100vw, 0, 0);
  transition: all 0.8s;
}

.wedo-list div {
  font-size: $p;
  list-style-type: none;
  display: flex;
  align-items: center;
}


@media (width < 980px) {
  .wedo {
    flex-direction: column-reverse;
  }

  .wedo-img {
    width: 100%;
    flex-direction: column-reverse;
  }

  .point-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .point-wrapper p {
    width: 80%;
  }

  .wedo-list {
    margin: 0;
  }
  .wedo-info {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: end;
    width: 100vw;
  }

  .wedo-img img {
    width: 95vw;
    height: 30vh;
    margin-top: 5vh;
  }


  .point-wrapper p {
    font-size: 18px;
  }
}


@media (width < 980px) {
  .wedo-wrapper {
    padding: 10px 10px;
    height: 100%;

  }

  .point {
    margin: 0;
  }

  .wedo-info {
    align-items: center;
    width: 100%;
  }
}

//.point {
//  width: 100px; height: 100px;
//  border-radius: 50%;
//  background: $globalAccent;
//  background-image: linear-gradient(to right, transparent 50%, currentColor 0);
//  color: $white;
//}

//.point::before {
//  content: '';
//  display: block;
//  margin-left: 50%;
//  height: 100%;
//  border-radius: 0 100% 100% 0 / 50%;
//  background-color: inherit;
//  transform-origin: left;
//  animation: spin 0.5s linear 1;
//}
//
//@keyframes spin {
//  to { transform: rotate(.5turn); }
//}
//@keyframes bg {
//  50% { background: currentColor; }
//}