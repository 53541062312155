* {
    box-sizing: border-box;
}



h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-size: 3.3vw;
    white-space: pre-line;
    /*font-size: 20px;*/
    cursor: default;
}

div {
    cursor: default;

}
p {
    margin: 0;
    white-space: pre-line;
    font-size: 1.4vw;
    cursor: default;
line-height: 1.5;
}

a {
    text-decoration: none;
}

img {
    border-radius: 15px;
    object-fit: cover;
}

.App {
    width: 100vw;
    overflow: hidden;
    max-height: 100%;
}

.non-animation {
    /*transform: translate(0, 0) !important;*/
    transition: transform 0s !important;
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important;
}

.home {
    /*padding-top: 100vh;*/
    /*padding-bottom: 100vh;*/
    /*z-index: 100;*/
}

.scrollable {
    transition: transform 0.2s;
}

.content {
    background: #fff;
}

html, body {
    height: 100vh;
}

.scroll {
    /*height: 100vh;*/
    /*position: relative;*/
    width: 100%;
    z-index: 100
}


.button-white {
    height: 50px;
    font-size: 18px;
    background: none;
    margin: 0px 10px;
    border: 1px solid #0AACBE;
    border-radius: 30px;
    color: #0AACBE;
    padding: 10px;

}

.button-blue {
    height: 50px;
    font-size: 18px;
    background: none;
    margin: 0px 10px;
    border: 1px solid #0AACBE;
    border-radius: 30px;
    color: #0AACBE;
    padding: 10px;
    cursor: pointer;
}