@import 'src/assets/variables';

.main {
  background-color: $white;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-show {
  z-index: 100;
  opacity: 1;
  border-radius: 0;
}

.img-hidden {
  z-index: 0;
  opacity: 0;
}

.background-white {
  background: white;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.main-header {
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 10px 150px;
}

.main-img {
  opacity: 0;
  min-width: 100vw;
  min-height: 100vh;
  border-radius: 0;
  transition: all 1s;
}

.video {
  height: 100vh;
  width: 100vw;
  //background-image: url('../../assets/main-img.png');
  background-color: #0AACBE;
  background-repeat: no-repeat;
  background-size: cover;
}


#mainVideo {
  position: absolute;
  min-width: 100%;
  height: 120%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  object-fit: cover;
  opacity: 0;
  transition: all 1s;
}

.tagline {
  width: 100%;
  position: absolute;
  top: 50%;
  text-align: center;
  text-transform: uppercase;
  color: $white;
  font-size: $h1;
  z-index: 2;
}

.main-watches {
  z-index: 200;
  height: 100%;
}

@media (width < 980px) {
  .tagline {
    margin: 0px 0px 0 0px;
    font-size: 30px;
  }

  .main-header {
    padding: 10px 10px;
  }
}

@media (width < 450px) {
  .tagline {
    font-size: 26px;
  }
}
