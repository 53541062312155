
.pie-chart-container {
  display: flex;
  align-items: center;
}

.pie-chart {
  background: conic-gradient(brown 0.00%, black 0.00% 0%, blue 0% 0%, #6cc3e3 0% 17%, #8A759B 12% 20%, #CC6166 20% 40%, #33AD51 40%);
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

#color-red {
  background-color: #33AD51;
}

#color-orange {
  background-color: orange;
}

#color-yellow {
  background-color: yellow;
}

#color-green {
  background-color: green;
}

#color-blue {
  background-color: blue;
}

#color-black {
  background-color: black;
}

#color-brown {
  background-color: #33AD51;
}

.chart-diagram {
  width: 12vw;
}

@media (width < 980px) {
  .chart-diagram {
    width: 30vw;
  }
}